<template>
  <el-dialog
    v-model="showCounterpartyDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-counterparty-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-counterparty-dialog-header__title">
        {{ dialogTitle }}
      </h4>
    </template>

    <CounterpartyForm
      ref="counterpartyFormRef"
      :model="props.model"
      :showCounterpartyDialog="showCounterpartyDialog"
      :currentData="props.currentData"
      :counterpartyTag="counterpartyTag"
      @remoteMethod="remoteMethod"
      @onCheckCounterpartyDialog="resetList"
    />
  </el-dialog>

  <ElvMessageBox
    ref="executeRuleMessageBoxRef"
    :confirm-button-text="t('button.Execute')"
    :cancel-button-text="t('button.later')"
    :title="t('title.executeIdentifyCounterpartyRules')"
    :loading="executeLoading"
    class="elv-counterparty-execute-message-box"
    @onConfirmEvent="onConfirmExecuteRule"
    @onCancelEvent="onCancelExecuteRule"
  >
    <template #content>
      <span
        v-dompurify-html="t('message.executeIdentifyCounterpartyRulesInfo')"
        class="elv-confirm-info-header__title"
      ></span
    ></template>
  </ElvMessageBox>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import AccountsApi from '@/api/AccountsApi'
import { PlatformType } from '#/GlobalConfigTypes'
import TransactionsApi from '@/api/TransactionsApi'
import { reportContactType } from '@/config/reports'
import CounterpartyForm from './CounterpartyForm.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { find, uniq, cloneDeep, isEmpty } from 'lodash-es'
import { useAccountStore } from '@/stores/modules/accounts'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  tableType: {
    type: String,
    default: 'counterparty'
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const accountStore = useAccountStore()
const emit = defineEmits(['resetList'])
const counterpartyTag: any = ref([])

const originCounterpartyTag: any = ref([])

const tagLoading = ref(false)
const executeLoading = ref(false)
const counterpartyFormRef = useTemplateRef('counterpartyFormRef')
const executeRuleMessageBoxRef = useTemplateRef('executeRuleMessageBoxRef')
const platformData: any = ref({})
const showCounterpartyDialog = ref(false)

const dialogTitle = computed(() => {
  if (props.tableType === 'transactions') return t('title.createNewCounterparty')
  return props.model === 'edit' ? t('button.editCounterparty') : t('button.addCounterparty')
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const onCancelExecuteRule = () => {
  executeRuleMessageBoxRef.value?.onCheckMessageBoxDialog()
}

// 切换删除弹窗展示
const onCheckCounterpartyDialog = () => {
  showCounterpartyDialog.value = !showCounterpartyDialog.value
}

const resetList = () => {
  emit('resetList')
  onCheckCounterpartyDialog()
  if (props.model === 'add') {
    onCancelExecuteRule()
  }
}

const onCloseDialog = () => {
  platformData.value = {}
  counterpartyFormRef.value?.onResetForm()
}

const remoteMethod = async (query: string) => {
  if (query) {
    tagLoading.value = true
    const { data } = await AccountsApi.getCounterpartTag(entityId.value, { keywords: query })
    tagLoading.value = false
    counterpartyTag.value = data.map((item: any) => {
      return {
        label: item.name,
        value: item.name
      }
    })
  } else {
    counterpartyTag.value = cloneDeep(originCounterpartyTag.value)
  }
}

const onConfirmExecuteRule = async () => {
  try {
    executeLoading.value = true
    await TransactionsApi.executeAllCounterpartyTransformerRule(entityId.value)
    onCancelExecuteRule()
    entityStore.fetchEntityDetail(entityId.value)
    ElMessage.success(t('message.success'))
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    executeLoading.value = false
  }
}

defineExpose({ onCheckCounterpartyDialog, showCounterpartyDialog })

watch(
  () => showCounterpartyDialog.value,
  async () => {
    if (showCounterpartyDialog.value && props.model === 'edit' && !isEmpty(props.currentData)) {
      platformData.value = find(entityStore.platformList, (item: PlatformType) => {
        return item.platformId === props.currentData.platformId
      })
    } else if (showCounterpartyDialog.value && props.model === 'add') {
      await accountStore.fetchCounterpartTagList(entityId.value)
      const list = accountStore.counterpartTagList.map((item: any) => {
        return {
          label: item.name,
          value: item.name,
          counterpartyTagId: item.counterpartyTagId
        }
      })
      const defaultTag = reportContactType.map((item: any) => {
        return {
          label: item.label,
          value: item.value,
          counterpartyTagId: ''
        }
      })
      const tagList = [...defaultTag, ...list]
      originCounterpartyTag.value = uniq(tagList)
      originCounterpartyTag.value = originCounterpartyTag.value.filter((item: any) => {
        return (
          item.counterpartyTagId !== '' ||
          !originCounterpartyTag.value.some(
            (otherItem: any) => otherItem.value === item.value && otherItem.counterpartyTagId !== ''
          )
        )
      })
      counterpartyTag.value = cloneDeep(originCounterpartyTag.value)
    }
  }
)
</script>

<style lang="scss">
.elv-counterparty-dialog {
  width: 620px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-counterparty-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .elv-counterparty-dialog-content__platform {
      margin: 0 auto 16px;
      display: flex;
      width: 182px;
      height: 80px;
      padding: 0px 15px 0px 17px;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .el-textarea {
      width: 572px;
    }

    .el-input {
      width: 572px;
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-select:not(.elv-account-accountType-short-select, .elv-counterparty-accountType-short-select) {
      width: 572px;
    }

    .elv-counterparty-accountType-short-select {
      .el-input,
      .el-select__wrapper {
        width: 282px;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      &.elv-form-item__fileType {
        margin-bottom: 8px;
      }

      &.elv-counterparty-form-item-platform {
        .el-form-item__content {
          width: 387px;
          margin-left: 8px;
          justify-content: space-between;
        }

        .el-form-item__label {
          width: 387px;
          margin-left: 8px;
        }
      }

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;

        &::before {
          // 隐藏掉必填的小红星
          display: none;
        }
      }
    }

    .el-select {
      &:hover:not(.el-select--disabled) .el-input__wrapper,
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      .el-select__selected-item {
        .el-tag {
          padding: 0 16px;
          height: 24px;
          border-radius: 40px;
          border: 1px solid #d0d4d9;
          box-sizing: border-box;
          color: #636b75;
          background-color: #fff;
          leading-trim: both;
          text-edge: cap;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-counterparty-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }

    .elv-counterparty-accountType-select-content {
      display: flex;
      align-items: flex-end;
      margin-bottom: 16px;

      .el-form-item {
        margin-bottom: 0px;
      }

      .elv-counterparty-date-select {
        box-sizing: border-box;
        height: 44px;
        width: 282px;
        padding: 8px 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #ffffff;
        border: 1px solid #dde1e6;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        position: relative;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition-property: border, box-shadow;

        &.is-short {
          width: 178px;
        }

        > div {
          display: flex;
          flex-direction: column;

          p {
            font-family: 'Plus Jakarta Sans';
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #aaafb6;
            margin-bottom: 8px;
          }

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: #0e0f11;
          }
        }

        &:hover,
        &.is-focus {
          cursor: pointer;
          border: 1px solid #5e85eb;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

          svg {
            fill: #5e85eb;
          }
        }

        svg {
          position: absolute;
          right: 12px;
          bottom: 12px;
          fill: #838d95;
          transition: transform 0.3s;
        }

        .elv-counterparty-date-time-value {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .elv-counterparty-date-scope {
        color: #000;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-counterparty-execute-message-box {
  .elv-confirm-info-header__title {
    text-align: center;
  }
}

.elv-counterparty-dialog-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    .elv-counterparty-dialog-platform-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}
</style>
