<template>
  <el-form
    ref="counterpartyFormRef"
    v-loading="counterpartyDetailLoading"
    :model="counterpartyForm"
    :rules="rules"
    label-position="top"
  >
    <el-form-item :label="t('title.counterpartyName')" :prop="counterpartyForm.type !== 'UNNAMED' ? 'name' : ''">
      <el-input v-model="counterpartyForm.name" :placeholder="t('common.name')" />
    </el-form-item>
    <el-form-item :label="t('common.type')" prop="type">
      <el-select v-model="counterpartyForm.type" placeholder=" " popper-class="elv-counterparty-dialog-popper">
        <el-option
          v-for="(item, index) in counterpartyTypeOptions"
          :key="index"
          :label="transformI18n(item.label)"
          :value="item.value"
        />
      </el-select>
    </el-form-item>
    <template v-if="props.model !== 'edit'">
      <div class="elv-counterparty-accountType-select-content">
        <el-form-item :label="t('common.platformType')" prop="accountType">
          <el-select
            v-model="counterpartyForm.accountType"
            placeholder=" "
            clearable
            :disabled="props.model === 'add' && !isEmpty(props.currentData)"
            popper-class="elv-counterparty-dialog-popper"
            :class="{
              'elv-counterparty-accountType-short-select': counterpartyForm.accountType !== ''
            }"
            @clear="onClearAccountType"
            @change="onChangeAccountType"
          >
            <el-option
              v-for="(item, index) in accountTypeOptions"
              :key="index"
              :label="transformI18n(item.label)"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="counterpartyForm.accountType !== ''"
          :label="accountLabel"
          class="elv-counterparty-form-item-platform"
          prop="contact.platformId"
        >
          <el-select
            v-model="counterpartyForm.contact.platformId"
            placeholder=" "
            filterable
            :disabled="
              props.model === 'add' && !isEmpty(props.currentData) && props.currentData.platform?.slug !== 'other'
            "
            class="elv-counterparty-accountType-short-select"
            popper-class="elv-counterparty-dialog-popper"
          >
            <el-option v-for="(item, index) in platformList" :key="index" :label="item.name" :value="item.platformId">
              <div class="elv-counterparty-dialog-platform-option">
                <img :src="item.logo" :alt="item.name" />
                <p>{{ item.name }}</p>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <el-form-item v-if="counterpartyForm.accountType !== ''" :label="identityLabel" prop="contact.identity">
        <el-input
          v-model.trim="counterpartyForm.contact.identity"
          :disabled="(props.model === 'add' && !isEmpty(props.currentData)) || props.model === 'edit'"
          :placeholder="identityLabel"
        />
      </el-form-item>

      <el-form-item :label="t('common.tag')">
        <el-select
          v-model="counterpartyForm.tags"
          multiple
          filterable
          allow-create
          default-first-option
          :reserve-keyword="false"
          :max-collapse-tags="10"
          :remote-method="remoteMethod"
          :loading="props.tagLoading"
          :placeholder="t('placeholder.inputTag')"
          popper-class="elv-counterparty-dialog-popper"
        >
          <el-option
            v-for="(item, index) in counterpartyTag"
            :key="index"
            :label="transformI18n(item.label)"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="t('report.memo')">
        <el-input
          v-model="counterpartyForm.memo"
          :rows="3"
          type="textarea"
          :placeholder="t('placeholder.descriptionAccount')"
        />
      </el-form-item>
    </template>
  </el-form>
  <div class="el-dialog__footer">
    <elv-button
      type="primary"
      round
      height="44"
      width="113"
      class="elv-counterparty--dialog-footer__button"
      :loading="submitLoading"
      :disabled="disabledSave"
      @click="onClickConnect"
      >{{ t('button.save') }}</elv-button
    >
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import AccountsApi from '@/api/AccountsApi'
import { transformI18n } from '@/i18n/index'
import { find, isEmpty, pick } from 'lodash-es'
import { useEntityStore } from '@/stores/modules/entity'
import { counterpartyTypeOptions } from '@/config/reports'
import type { FormInstance, FormRules } from 'element-plus'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  counterpartyTag: {
    type: Array<any>,
    default: () => {
      return []
    }
  },
  tagLoading: {
    type: Boolean,
    default: false
  },
  showCounterpartyDialog: {
    type: Boolean,
    required: true
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()

const { bankList, chainList, exchangeList, custodyList, paymentList } = storeToRefs(entityStore)

const emit = defineEmits(['remoteMethod', 'onCheckCounterpartyDialog'])

const accountTypeOptions = ref([
  {
    label: t('common.onChain'),
    value: 'CHAIN',
    platformLabel: t('common.chain'),
    identityLabel: t('common.address')
  },
  {
    label: t('common.bank'),
    value: 'BANK',
    platformLabel: t('common.contentTextName', { content: t('common.bank') }),
    identityLabel: t('common.bankAccount')
  },
  {
    label: t('common.exchange'),
    value: 'EXCHANGE',
    platformLabel: t('common.contentTextName', { content: t('common.exchange') }),
    identityLabel: t('common.accountId')
  },
  {
    label: t('common.custody'),
    value: 'CUSTODY',
    platformLabel: t('common.contentTextName', { content: t('common.custody') }),
    identityLabel: t('common.accountId')
  },
  {
    label: t('common.payment'),
    value: 'PAYMENT',
    platformLabel: t('common.contentTextName', { content: t('common.payment') }),
    identityLabel: t('common.accountId')
  }
])

const counterpartyForm: any = reactive({
  type: 'INDIVIDUAL',
  name: '',
  contact: {
    identity: '',
    platformId: ''
  },
  memo: '',
  accountType: '',
  tags: []
})

const submitLoading = ref(false)
const counterpartyDetailLoading = ref(false)
const counterpartyFormRef = useTemplateRef<FormInstance>('counterpartyFormRef')

const rules = reactive<FormRules>({
  type: {
    required: true,
    trigger: 'blur'
  },
  name: {
    required: true,
    trigger: 'blur',
    message: 'Name is required'
  },
  'contact.platformId': {
    required: true,
    trigger: 'blur',
    validator(rule: any, value: string) {
      if (!value && (counterpartyForm.accountType !== '' || counterpartyForm.contact.identity !== '')) {
        return new Error(`platform is required`)
      }
      return true
    }
  },
  'contact.identity': {
    required: true,
    trigger: 'blur',
    validator(rule: any, value: string) {
      if (!value && (counterpartyForm.accountType !== '' || counterpartyForm.contact.platformId !== '')) {
        return new Error(`input is required`)
      }
      return true
    }
  },
  accountType: {
    required: true,
    trigger: 'blur',
    validator(rule: any, value: string) {
      if (!value && (counterpartyForm.contact.platformId !== '' || counterpartyForm.contact.identity !== '')) {
        return new Error(`Account type is required`)
      }
      return true
    }
  }
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const remoteMethod = (query: string) => {
  emit('remoteMethod', query)
}

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const accountLabel = computed(() => {
  return find(accountTypeOptions.value, { value: counterpartyForm.accountType })?.platformLabel
})

const identityLabel = computed(() => {
  return find(accountTypeOptions.value, { value: counterpartyForm.accountType })?.identityLabel
})

const platformList = computed(() => {
  let list: any = []
  switch (counterpartyForm.accountType) {
    case 'CHAIN':
      list = chainList.value
      break
    case 'BANK':
      list = bankList.value
      break
    case 'EXCHANGE':
      list = exchangeList.value
      break
    case 'CUSTODY':
      list = custodyList.value
      break
    case 'PAYMENT':
      list = paymentList.value
      break
    default:
      break
  }
  return list
})

const disabledSave = computed(() => {
  return (
    (props.model === 'add' &&
      (!counterpartyForm.name ||
        (counterpartyForm.accountType !== '' &&
          (!counterpartyForm.contact.platformId || !counterpartyForm.contact.identity)))) ||
    (props.model === 'edit' &&
      counterpartyForm.name === props.currentData.accountName &&
      counterpartyForm.type === props.currentData?.type)
  )
})

const onChangeAccountType = () => {
  counterpartyForm.contact.platformId = ''
  counterpartyForm.contact.identity = ''
}

const onClearAccountType = () => {
  counterpartyForm.accountType = ''
  counterpartyForm.contact.platformId = ''
  counterpartyForm.contact.identity = ''
}

const onClickConnect = async () => {
  if (!counterpartyFormRef.value) return
  await counterpartyFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        submitLoading.value = true
        const params: any = pick(counterpartyForm, ['type', 'name', 'contact', 'memo'])
        params.tags = counterpartyForm.tags.map((item: any) => {
          const tag = find(props.counterpartyTag, { value: item })
          if (tag?.counterpartyTagId) {
            return {
              counterpartyTagId: tag?.counterpartyTagId,
              entityId: entityId.value,
              name: tag.name ? tag.name : item
            }
          }
          return {
            counterpartyTagId: 0,
            entityId: entityId.value,
            name: item?.name ? item?.name : item
          }
        })
        if (props.model === 'edit') {
          if (
            ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
            !currentEntityPermission.value?.counterparty?.update
          ) {
            ElMessage.warning(t('message.noPermission'))
            return
          }
          await AccountsApi.editCounterparty(entityId.value, props.currentData?.counterpartyId, {
            type: counterpartyForm.type,
            name: counterpartyForm.name
          })
        } else {
          if (
            ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
            !currentEntityPermission.value?.counterparty?.create
          ) {
            ElMessage.warning(t('message.noPermission'))
            return
          }
          if (counterpartyForm.accountType === '') {
            delete params.contact
          }
          await AccountsApi.addCounterparty(entityId.value, params)
        }
        ElMessage.success(t('message.saveSuccess'))
        emit('onCheckCounterpartyDialog')
      } catch (error: any) {
        ElMessage.error(error?.message)
        console.log(error)
      } finally {
        submitLoading.value = false
      }
    }
  })
}

const onResetForm = () => {
  counterpartyFormRef.value?.resetFields()
  counterpartyForm.tags = []
  counterpartyForm.memo = ''
}

defineExpose({ formRef: counterpartyFormRef, onResetForm })

watch(
  [() => props.showCounterpartyDialog, () => props.currentData],
  async () => {
    if (props.showCounterpartyDialog) {
      if (props.model === 'edit' && props.currentData?.counterpartyId) {
        try {
          counterpartyDetailLoading.value = true
          const { data } = await AccountsApi.getCounterpartDetail(entityId.value, props.currentData?.counterpartyId)
          counterpartyForm.name = data?.name
          counterpartyForm.type = data?.type
        } catch (error: any) {
          console.log(error)
        } finally {
          counterpartyDetailLoading.value = false
        }
      }
      if (props.model === 'add' && !isEmpty(props.currentData)) {
        counterpartyForm.accountType = props.currentData.platform?.type
        counterpartyForm.contact.platformId = props.currentData?.platform?.platformId
        counterpartyForm.contact.identity = props.currentData?.identity ?? props.currentData?.contactIdentity
      }
    }
  },
  { immediate: true, flush: 'post' }
)
</script>

<style lang="scss" scoped></style>
